.selectField{
    margin-right:1.3rem !important;
    margin-top: 2rem;
}

.specialization{
    display: flex;
    justify-content: center;
    place-items: center;
}

.inputTypograph{
    font-size: 13px !important;
    margin-bottom: 8px;
}

.langTable td{
    padding:'8px' !important;
}

.tableCell{
    vertical-align: bottom;
    height: '30px'
}

.formCard{
    padding: 3rem !important;
}

.subSpecMultiple{
    width: 15rem;
}

@media only screen and (max-width: 700px) {
    .formCard{
        padding: 1rem !important;
    }
    .subSpecMultiple{
        width: 100% !important;
    }    
    .specDeleteIcon{
        width: 100%;
        border-bottom: 1px solid gray;
        margin-bottom: 1rem;
    }

    .root{
        overflow-x: auto;
    }
  }