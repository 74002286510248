.badge{
    padding: 0.5rem 1rem;
    border-radius: 9px;
    color: white;
}

.badge .Active{
    background-color: blue;
}

.badge .Inactive {
    background-color: rgb(124, 124, 0);
}

.badge .Block{
    background-color: red;
}

.userIdField{
    width: 5rem;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}