.adminLoginContainer{
    width: 100%;
    padding: 5rem;
    background-color: whitesmoke;
    height: 100vh;
}

.adminLoginCard{
    width:40%;
    margin:auto;
    padding:2rem;
}