/*
Author       : Dreamguys
Template Name: Truelysell - Bootstrap Reactjs Template
Version      : 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Table
3. Bootstrap Classes
4. Select2
5. Nav Tabs
6. Components
7. Avatar
8. Header
9. Sidebar
10. Content
11. Toggle Button
12. Login
13. Notifications
14. Dashboard
15. Profile
16. Responsive

========================================*/

/*-----------------
	1. General
-----------------------*/

@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 400;
	src: local('Circular Std Book'), url('../fonts/CircularStd-Book.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 400;
	src: local('Circular Std Book Italic'), url('../fonts/CircularStd-BookItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 500;
	src: local('Circular Std Medium'), url('../fonts/CircularStd-Medium.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 500;
	src: local('Circular Std Medium Italic'), url('../fonts/CircularStd-MediumItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 600;
	src: local('Circular Std Bold'), url('../fonts/CircularStd-Bold.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 600;
	src: local('Circular Std Bold Italic'), url('../fonts/CircularStd-BoldItalic.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: normal;
	font-weight: 800;
	src: local('Circular Std Black'), url('../fonts/CircularStd-Black.woff') format('woff');
}
@font-face {
	font-family: 'CircularStd';
	font-style: italic;
	font-weight: 800;
	src: local('Circular Std Black Italic'), url('../fonts/CircularStd-BlackItalic.woff') format('woff');
}
html {
	height: 100%;
}
body {
	background-color: #f8f9fa;
	color: #333;
	font-family: 'CircularStd', sans-serif;
	font-size: 1rem;
	height: 100%;
	line-height: 1.5;
	overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'CircularStd', sans-serif;
	margin-top: 0;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.table-responsive{
	white-space: nowrap;
}
.form-control {
    border: 1px solid #ddd;
    box-shadow: none;
    color: #333;
    font-size: 15px;
    height: 40px;
}
.form-control:focus {
	border-color: #bbb;
	box-shadow: none;
	outline: 0 none;
}
.form-control.form-control-sm {
	height: calc(1.5em + .5rem + 2px);
}

.form-control.form-control-lg {
	height: calc(1.5em + 1rem + 2px);
}
a {
	color: #009ce7;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
input, input:focus,
button, button:focus {
	outline: none;
}
input[type="file"] {
    height: auto;
    min-height: calc(1.5em + .75rem + 2px);
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.form-control {
	resize: vertical;
}
.navbar-nav > li {
	float: left;
}
.form-group {
	margin-bottom: 1.25rem;
}
.input-group .form-control {
	height: 40px;
}
.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
	background-color: rgba(0, 0, 0, 0.1);
	border-color: rgba(0, 0, 0, 0.1);
}
.font-weight-600 {
	font-weight: 600;
}

/*-----------------
	2. Table
-----------------------*/

.table {
	color: #333;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
.table td a {
    color: #333;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2 a {
	color: #333;
}
table.table td h2 a:hover {
	color: #ff7849;
}
table.table td h2 span {
	color: #888;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead tr th {
	font-weight: 600;
	border: 0;
}
.table tbody tr {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.table tbody tr:last-child {
	border-color: transparent;
}
.table.table-center td,
.table.table-center th {
	vertical-align: middle;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
}
.card-table .card-body .table tr td:first-child,
.card-table .card-body .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .card-body .table tr td:last-child,
.card-table .card-body .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}
.actions a {
	width: 85px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
	font-family: "FontAwesome";
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-white {
	background-color: #fff;
	border: 1px solid #ccc;
	color: #333;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.bg-primary,
.badge-primary {
	background-color: #ff7849 !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color:  #E13818 !important;
}
.bg-success,
.badge-success {
	background-color: #27c24c !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #23ad44 !important;
}
.bg-info,
.badge-info {
	background-color: #009efb !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #f05050 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #ee3939 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #ff7849 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #23ad44 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #f05050 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #009efb !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #757575 !important;
}
.btn-primary {
	background-color: #ff7849;
	border: 1px solid #ff7849;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color:  #E13818;
	border: 1px solid  #E13818;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color:  #E13818;
	border: 1px solid  #E13818;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color:  #E13818;
	border-color:  #E13818;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #ff7849;
    border-color: #ff7849;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #27c24c;
	border: 1px solid #27c24c
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #23ad44;
	border: 1px solid #23ad44;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #23ad44;
	border: 1px solid #23ad44
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #23ad44;
	border-color: #23ad44;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #27c24c;
    border-color: #27c24c;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #f05050;
	border: 1px solid #f05050;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #ee3939;
	border: 1px solid #ee3939;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #ee3939;
	border: 1px solid #ee3939;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #ee3939;
	border-color: #ee3939;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #f62d51;
    border-color: #f62d51;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #ff7849;
	border-color: #ff7849;
}
.btn-outline-primary:hover {
	background-color: #ff7849;
	border-color: #ff7849;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #ff7849;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #ff7849;
	border-color: #ff7849;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #27c24c;
	border-color: #27c24c;
}
.btn-outline-success:hover {
	background-color: #27c24c;
	border-color: #27c24c;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #27c24c;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #27c24c;
	border-color: #27c24c;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #f05050;
	border-color: #f05050;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #f05050;
	border-color: #f05050;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #f05050;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #f05050;
	border-color: #f05050;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #ff7849;
	border-color: #ff7849;
}
.pagination > li > a, 
.pagination > li > span {
	color: #ff7849;
}
.page-link:hover {
	color: #ff7849;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #ff7849;
	border-color: #ff7849;
}
.dropdown-menu {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	transform-origin: left top 0;
	box-shadow: inherit;
	background-color: #fff;
}
.dropdown-item.active, .dropdown-item:active {
    background-color: #ff7849;
}
.navbar-nav .open .dropdown-menu {
	border: 0;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}
.settings-tab .nav .nav-link {
	margin-bottom: 15px;
	color: #333;
}
.settings-tab .nav .nav-link:hover {
	background: #e8e8e8;
	color: #333;
}
.settings-tab .nav .nav-link.active, .settings-tab .nav .show > .nav-link {
	background: #ff7849;
	color: #fff;
}
.dropdown-menu {
	font-size: 14px;
}
.card {
    border: 0;
    border-radius: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.875rem;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
}
.card .card-header .card-title {
    margin-bottom: 0;
}
.modal-footer.text-left {
	text-align: left;
}
.modal-footer.text-center {
	text-align: center;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #ff7849;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today:before{
	border-bottom-color:#ff7849;
}
.bg-info-light{
	background-color:rgba(2, 182, 179,0.12) !important;
	color:#1db9aa !important;
}
.bg-primary-light{
	background-color:rgba(17, 148, 247,0.12) !important;
	color:#2196f3 !important;
}
.bg-danger-light{
	background-color:rgba(242, 17, 54,0.12) !important;
	color:#e63c3c !important;
}
.bg-warning-light{
	background-color:rgba(255, 152, 0,0.12) !important;
	color:#f39c12 !important;
}
.bg-success-light{
	background-color:rgba(15, 183, 107,0.12) !important;
	color:#26af48 !important;
}
.bg-purple-light{
	background-color:rgba(197, 128, 255,0.12) !important;
	color:#c580ff !important;
}
.bg-default-light{
	background-color:rgba(40, 52, 71,0.12) !important;
	color:#283447 !important;
}

/*-----------------
	4. Select2
-----------------------*/

.select2-container .select2-selection--single {
	border: 1px solid #ddd;
	height: 40px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 38px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ddd transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ddd;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #333;
	font-size: 15px;
	font-weight: normal;
	line-height: 38px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #ff7849;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #ddd;
	min-height: 40px;
}

/*-----------------
	5. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #e6e6e6;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #ff7849;
	border-color: #ff7849;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #333;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #333;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #ff7849;
	border-color: #ff7849;
	color: #fff;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #ff7849;
}
.nav-tabs.nav-tabs-bottom > li {
	margin-bottom: -1px;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #ff7849;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fafafa;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}

/*-----------------
	6. Components
-----------------------*/

.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}

/*-----------------
	7. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #ff7849;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
.avatar-away:before,
.avatar-offline:before,
.avatar-online:before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online:before {
	background-color: #27c24c;
}
.avatar-offline:before {
	background-color: #f05050;
}
.avatar-away:before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away:before,
.avatar-xs.avatar-offline:before,
.avatar-xs.avatar-online:before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away:before,
.avatar-sm.avatar-offline:before,
.avatar-sm.avatar-online:before {
	border-width: 2px;
}

/*-----------------
	8. Header
-----------------------*/

.header {
	background: #3b3b3b;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 3;
	height: 60px;
	transition: all 0.45s ease 0s;
}
.header .header-left {
	float: left;
	height: 60px;
	padding: 0 20px;
	position: relative;
	text-align: center;
	width: 280px;
	z-index: 1;
	transition: all 0.2s ease-in-out;
	display: none;
}
.header .header-left .logo {
	display: inline-block;
	line-height: 60px;
}
.header .header-left .logo img {
    max-height: 24px;
    width: auto;
}
.header-left .logo.logo-small {
    display: none;
}
.header .dropdown-menu > li > a {
	position: relative;
}
.header .dropdown-toggle:after {
	display: none;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #fff;
	border-right: 2px solid #fff;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
    padding: 12px 0;
}
.user-menu.nav > li > a {
	color: #333;
	font-size: 14px;
	padding: 0 15px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: transparent;
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #333;
}
.user-img {
	display: inline-block;
	margin-right: 3px;
	position: relative;
}
.user-img img {
	width: 40px;
	height: 40px;
	-o-object-fit: cover;
	object-fit: cover;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.user-menu .dropdown-menu {
    min-width: 200px;
	padding: 0;
}
.user-menu .dropdown-menu .dropdown-item {
    padding: 7px 15px;
}
.user-menu .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    padding: 10px 15px;
}
.user-menu .dropdown-menu .dropdown-item:hover {
    color: #ff7849;
}
.header .dropdown-menu > li > a:focus,
.header .dropdown-menu > li > a:hover {
	background-color: #ff7849;
	color: #fff;
}
.header .dropdown-menu > li > a:focus i,
.header .dropdown-menu > li > a:hover i {
	color: #fff;
}
.header .dropdown-menu > li > a {
	padding: 10px 18px;
}
.header .dropdown-menu > li > a i {
	color: #ff7849;
	margin-right: 10px;
	text-align: center;
	width: 18px;
}
.header .user-menu .dropdown-menu > li > a i {
	color: #ff7849;
	font-size: 16px;
	margin-right: 10px;
	min-width: 18px;
	text-align: center;
}
.header .user-menu .dropdown-menu > li > a:focus i,
.header .user-menu .dropdown-menu > li > a:hover i {
	color: #fff;
}
.mobile_btn {
	display: none;
	float: left;
}
.slide-nav .sidebar {
	margin-left: 0;
}
.user-header {
    background-color: #f9f9f9;
    display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
    margin-bottom: 2px;
}
.menu-title {
    color: #a3a3a3;
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
    padding: 0 25px;
}
.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    display: none;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}
.sidebar-overlay.opened {
    display: block;
}
html.menu-opened {
	overflow: hidden;
}
html.menu-opened body {
	overflow: hidden;
}

/*-----------------
	9. Sidebar
-----------------------*/

.sidebar {
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 280px;
    z-index: 1001;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	min-height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
    padding: 15px;
}
.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.sidebar-menu li a {
	color: #333;
	display: block;
	font-size: 16px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
    color: #ff7849;
}
.sidebar-menu > ul > li > a:hover {
    background-color: #e8e8e8;
    color: #000;
}
.sidebar-menu > ul > li.active > a:hover {
    background-color:  #E13818;
    color: #fff;
}
.sidebar-menu li.active a {
    background-color: #ff7849;
    color: #fff;
}
.menu-title {
    color: #9e9e9e;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 5px 15px;
    white-space: nowrap;
}
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #ff9b44;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
	color: #ff7849;
	text-decoration: underline;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 10px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	margin-left: auto;
}
.sidebar-menu ul ul a {
    display: block;
    font-size: 15px;
    padding: 7px 10px 7px 45px;
    position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 65px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 85px;
}
.sidebar-menu > ul > li {
    margin-bottom: 5px;
    position: relative;
}
.sidebar-menu > ul > li:last-child {
    margin-bottom: 0;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sidebar-menu ul ul a .menu-arrow {
	top: 10px;
}
.sidebar-menu > ul > li > a {
    align-items: center;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    padding: 10px 20px;
    position: relative;
    transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 20px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
}

/*-----------------
	10. Content
-----------------------*/

.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 280px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem 0;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-header .breadcrumb {
    background-color: transparent;
    color: #6c757d;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
}
.page-header .breadcrumb a {
	color: #333;
}
.page-title {
    color: #333;
    margin-bottom: 5px;
}

/*-----------------
	11. Toggle Button
-----------------------*/

.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}
.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
	background-color: #55ce63;
}
.check:checked + .checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
	margin-left: auto;
    position: relative;
	width: 73px;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; 
	overflow: hidden; 
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
	background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
	height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}
.custom-modal .modal-body {
    padding: 30px;
}

/*-----------------
	12. Login
-----------------------*/

.login-wrapper {
	width: 100%;
	height: 100%;
	display: table-cell;
	vertical-align: middle;
}
.loginbox {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
    max-width: 500px;
	padding: 40px;
    width: 100%;
}
.account-logo img {
	max-height: 80px;
	width: auto;
}

/*-----------------
	13. Notifications
-----------------------*/

.notifications {
	padding: 0;
}
.notifications .notification-time {
	font-size: 12px;
	line-height: 1.35;
	color: #bdbdbd;
}
.notifications .media {
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications .media:last-child {
	border-bottom: none;
}
.notifications .media a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications .media a:hover {
	background-color: #fafafa;
}
.notifications .media > .avatar {
	margin-right: 10px;
}
.notifications .media-list .media-left {
	padding-right: 8px;
}
.topnav-dropdown-header {
	border-bottom: 1px solid #eee;
	text-align: center;
}
.topnav-dropdown-header, 
.topnav-dropdown-footer {
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    padding-left: 15px;
    padding-right: 15px;
}
.topnav-dropdown-footer {
	border-top: 1px solid #eee;
}
.topnav-dropdown-footer a {
	display: block;
	text-align: center;
	color: #333;
}
.user-menu.nav > li > a .badge {
    background-color: #f43f3f;
    display: block;
    font-size: 10px;
    font-weight: bold;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 7px;
    top: 7px;
    border: 2px solid #fff;
    line-height: unset;
    padding: 0;
}
.user-menu.nav > li > a > i {
	font-size: 1.5rem;
}
.noti-details {
	color: #989c9e;
    margin-bottom: 0;
}
.noti-title {
	color: #333;
}
.notifications .noti-content {
	height: 290px;
	width: 350px;
	overflow-y: auto;
	position: relative;
}
.notification-list {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifications ul.notification-list > li{
	margin-top: 0;
	border-bottom: 1px solid #f5f5f5;
}
.notifications ul.notification-list > li:last-child {
	border-bottom: none;
}
.notifications ul.notification-list > li a {
	display: block;
	padding: 10px 15px;
	border-radius: 2px;
}
.notifications ul.notification-list > li a:hover {
	background-color: #fafafa;
}
.notifications ul.notification-list > li .list-item {
    border: 0;
    padding: 0;
    position: relative;
}
.topnav-dropdown-header .notification-title {
    color: #333;
    display: block;
    float: left;
    font-size: 14px;
}
.topnav-dropdown-header .clear-noti {
    color: #f83f37;
    float: right;
    font-size: 12px;
    text-transform: uppercase;
}
.noti-time {
    margin: 0;
}

/*-----------------
	14. Dashboard
-----------------------*/

.dash-widget-icon {
    align-items: center;
    border-radius: 10px;
    color: #fff;
    display: inline-flex;
    font-size: 40px;
    height: 80px;
    justify-content: center;
    text-align: center;
    width: 80px;
    background-color: #ff7849;
}
.dash-count {
    font-size: 18px;
    margin-left: auto;
}
.dash-widget-info {
    margin-left: auto;
    text-align: right;
}
.dash-widget-info h3 {
    margin-bottom: 10px;
	font-size: 1.5rem;
}
.dash-widget-info h6 {
    margin-bottom: 0;
}
.dash-widget-header {
    align-items: center;
    display: flex;
}
.submit-section {
	text-align: center;
	margin-top: 40px;
}
.submit-btn {
    border-radius: 50px;
    font-size: 18px;
    font-weight: 600;
    min-width: 200px;
    padding: 10px 20px;
}

/*-----------------
	15. Profile
-----------------------*/

.profile-menu {
    background-color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    padding: 0.9375rem 1.5rem;
}
.profile-menu .nav-tabs.nav-tabs-solid {
	background-color: transparent;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.cal-icon:after {
	color: #979797;
	content: "\f073";
	display: block;
    font-family: "Font Awesome 5 Free";
    font-weight: 500;
    font-size: 15px;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.main-wrapper > .header {
    background: #fff;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
    left: 310px;
    position: fixed;
    right: 50px;
    top: 50px;
    border-radius: 10px;
}
.page-wrapper {
	border-radius: 25px;
	background-color: #f5f5f5;
	padding-top: 90px;
	min-height: calc(100vh - 40px);
}
.main-wrapper {
	background-color: #fff;
	height: auto;
	padding: 20px 20px 20px 0;
}
.card {
    border: 0;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
}
.header.fixed-header {
    margin: 0;
    right: 0;
    border-radius: 0;
    transition: all 0.45s ease 0s;
    top: 0;
    left: 280px;
    background-color: #ff7849;
}
.sidebar-logo {
    display: flex;
    padding: 20px 20px 0;
    justify-content: center;
}
.sidebar-logo img {
	max-height: 46px;
	width: auto;
}
.pricing-box .pricing-selected .card {
    background-color: #ff7849;
    border-color: #ff7849;
    color: #fff;
}
.pricing-box .pricing-selected .pricing-header h2 {
    color: #fff;
}
.pricing-box .pricing-selected .pricing-header p {
	color: #fff;
}
.pricing-box .pricing-selected .pricing-card-price .heading2 {
	color: #fff;
}
.pricing-box .pricing-selected .pricing-card-price p {
	color: #fff;
}
.pricing-box .pricing-selected .btn {
	background-color: #fff;
	color: #ff7849;
}
.pricing-box .pricing-selected .pricing-options li {
	color: #fff;
}
.pricing-box .pricing-header {
	margin-bottom: 2rem;
}
.pricing-box .pricing-header h2 {
	margin-bottom: 0;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.2;
}
.pricing-box .pricing-header p {
	color: #9b9b9b;
	font-size: .875rem;
	line-height: 1.43;
}
.pricing-box .pricing-card-price {
	margin-bottom: 1.563rem;
}
.pricing-box .pricing-card-price .heading2 {
	margin-bottom: 5px;
}
.pricing-box .pricing-card-price p {
	color: #9b9b9b;
}
.pricing-box .pricing-card-price p + p {
	margin: 0;
}
.pricing-box .pricing-options {
	list-style: none;
	padding: 0;
	margin-bottom: 1.875rem;
}
.pricing-box .pricing-options li {
	margin-bottom: 12px;
	font-size: 0.875rem;
	line-height: 1.43;
	position: relative;
}
.add-button {
    box-shadow: 0px 0px 12px 0px rgba(255, 0, 128, 0.2);
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
}
.filter-btn {
    box-shadow: 0px 0px 12px 0px rgba(55, 73, 72, 0.02);
    border: 0;
    width: 45px;
    height: 45px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #ff7849;
    border-radius: 10px;
}
.filter-btn:hover, .filter-btn:focus {
    background-color: #ff7849;
	color: #fff;
}
.filter-card .form-group {
	margin-bottom: 1.5rem;
}
.has-error .help-block {
	color: red;
}
small.help-block {
   color: #F44336 !important;
}
.toggle.ios, .toggle-on.ios, .toggle-off.ios {
	border-radius: 20px;
}
.toggle.ios .toggle-handle {
	border-radius: 20px;
}
.service-header {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.service-header h2 {
	font-weight: bold;
}
.service-cate a {
    color: #fff;
    padding: 2px 10px;
    text-transform: uppercase;
    background: #d9c505;
    border-radius: 4px;
    font-size: 13px;
    display: inline-block;
}
.service-images {
    margin-bottom: 30px;
}
.widget {
    margin-bottom: 30px;
}
.service-amount {
    color: #ff7849;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
	margin-left: auto;
}
.about-author {
    min-height: 80px;
}
.about-provider-img {
    background-color: #fff;
    height: 80px;
    overflow: hidden;
    position: absolute;
    width: 80px;
}
.provider-details {
    margin-left: 100px;
}
.ser-provider-name {
    display: inline-block;
    margin-bottom: 5px;
    color: #272b41;
    font-size: 16px;
    font-weight: 600;
}
.last-seen {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.last-seen i {
    color: #ffbc34;
    margin-right: 5px;
    font-size: 12px;
}
.last-seen i.online {
    color: #00e65b;
}
.provider-info {
    background-color: #f9f9f9;
    padding: 15px;
    border-radius: 4px;
}
.available-widget ul {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;
}
.available-widget ul li {
    color: #858585;
    overflow: hidden;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}
.available-widget ul li span {
	float: left;
}
.available-widget ul li:first-child {
	padding-top: 0;
}
.available-widget ul li:last-child {
	border-bottom: none;
	padding-bottom: 0;
}
.rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.rating i {
    color: #dedfe0;
}
.rating i.filled {
    color: #fbc418;
}
.service-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.service-widget .rating i {
	font-size: 14px;
}
.nav-tabs.menu-tabs {
	margin-bottom: 30px;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link {
	color: #ff7849;
}
.nav-tabs.menu-tabs .nav-item {
	padding-right: 2.25rem;
}
.nav-tabs.menu-tabs .nav-link {
	padding: 0 0 1rem;
	font-weight: 700;
	position: relative;
	border: none;
	outline: none;
	display: inline-flex;
	align-items: center;
	background: transparent;
}
.nav-tabs.menu-tabs .nav-link::before, .nav-tabs .nav-link::after {
	position: absolute;
	content: '';
}
.nav-tabs.menu-tabs .nav-link::after {
	left: 0;
	right: 0;
	bottom: 0;
	height: 3px;
	background: #ff7849;
	opacity: 0;
}
.nav-tabs.menu-tabs .nav-item.active .nav-link::after {
	opacity: 1;
}
.nav-tabs.menu-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
	background: transparent;
}
.nav-tabs.menu-tabs .nav-link:focus {
	color: #ff7849;
}
.nav-tabs.menu-tabs .nav-link .badge {
    margin-left: 0.25rem;
}
.noti-dropdown > a {
    display: inline-flex;
    position: relative;
    z-index: 1;
    padding: 6px !important;
    min-width: 36px;
}
.noti-dropdown > a::before {
    position: absolute;
    z-index: -1;
    height: 20px;
    width: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    content: '';
    background-color: #e5e5e5;
    border-radius: 50%;
    opacity: 0;
    transition: all .3s;
}
.noti-dropdown > a:hover::before {
    opacity: 1;
    height: 40px;
    width: 40px;
}
.login-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    pointer-events: none;
    height: 50vh;
    background-color: #ff7849;
    border-radius: 0 0 2.5rem 2.5rem;
	z-index: 0;
}
.login-page {
	background-color: #f5f5f5;
	position: relative;
	padding: 0;
	margin: -20px -20px -20px 0;
}
.login-body {
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 15px;
    min-height: 100vh;
    display: flex;
}
.login-header {
	margin-bottom: 20px;
}
.login-header p {
	margin-bottom: 0;
}
.login-header h3 {
    font-size: 20px;
    margin-bottom: 0;
    text-transform: uppercase;
}
.login-header h3 span {
	color: #ff7849;
}
.login-body .form-control {
	height: 50px;
}
.account-btn {
    font-size: 20px;
    font-weight: 500;
    display: block;
    width: 100%;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
}
.login-body .form-control::placeholder{color:#bbb;opacity:1}
.filter-card .form-group {

    margin-bottom: 1.5rem;
    position: relative;
}
.filter-card .form-group label {
    position: absolute;
    background: white;
    font-size: 12px;
    left: 10px;
    top: -7px;
    padding: 0 7px;
    color: #858585;
	z-index: 1;
}
.filter-card .form-control {
	height: 48px;
}
.filter-card .btn {
	height: 48px;
}
.filter-card .select2-container .select2-selection--single {
    height: 48px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 46px;
}
.filter-card .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 46px;
}
#toggle_btn {
	align-items: center;
	color: #333;
	display: inline-flex;
	float: left;
	font-size: 26px;
	height: 60px;
	justify-content: center;
	padding: 0 20px;
}
.service-carousel .owl-nav {
	width: 100%;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
	margin: 0;
}
.service-carousel:hover .owl-nav{
	opacity:1;
	visibility:visible;
}
.service-carousel .owl-carousel .owl-nav div {
	height: 48px;
	width: 48px;
	line-height: 48px;
	top: 50%;
	background: #666;
	color: #fff;
	font-size: 25px;
	position: absolute;
	border: none;
	margin: 0;
	padding: 0;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	border-radius: 100%;
	margin-top: -24px;
}
.service-carousel .owl-carousel .owl-nav div:hover{
	color:#fff;
}
.service-carousel .owl-carousel .owl-nav .owl-prev{
	left:-75px;
}
.service-carousel .owl-carousel .owl-nav .owl-prev:hover{
	left:-65px;
}
.service-carousel .owl-carousel .owl-nav .owl-next{
	right:-75px;
}
.service-carousel .owl-carousel .owl-nav .owl-next:hover{
	right:-65px;
}
.service-carousel .owl-dots{
	margin-top:50px;
}
.service-carousel .owl-dots .owl-dot{
	margin-right:5px;
}
.service-carousel .owl-dots .owl-dot:last-child{
	margin-right:0px;
}
.service-carousel .owl-dots .owl-dot span{
	width: 12px;
	height: 12px;
	line-height: 12px;
	margin:0;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.service-carousel .owl-dots .owl-dot.active span {
	border-color: transparent;
	width: 30px;
	height: 12px;
}
.check {
	display: block;
	margin: 0;
	padding: 0;
	width: 0;
	height: 0;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}
.checktoggle {
    background-color: #e0001a;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 50%;
	left: 0;
	transform: translate(5px, -50%);
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-radius: 50%;
	transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
	background-color: #55ce63;
}
.check:checked + .checktoggle:after {
	left: 100%;
	transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
	margin-left: auto;
    position: relative;
	width: 73px;
    -webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; 
	overflow: hidden; 
	cursor: pointer;
	border-radius: 20px;
	margin-bottom: 0;
}
.onoffswitch-inner {
	display: block;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    width: 200%;
}
.onoffswitch-inner:before, .onoffswitch-inner:after {
    box-sizing: border-box;
    color: #fff;
    display: block;
    float: left;
    font-size: 16px;
    height: 30px;
    line-height: 32px;
    padding: 0;
    width: 50%;
}
.onoffswitch-inner:before {
	background-color: #55ce63;
    color: #fff;
    content: "ON";
    padding-left: 14px;
}
.onoffswitch-inner:after {
    content: "OFF";
    padding-right: 14px;
    background-color: #ccc;
    color: #fff;
    text-align: right;
}
.onoffswitch-switch {
    background: #fff;
    border-radius: 20px;
    bottom: 0;
    display: block;
	height: 20px;
    margin: 5px;
    position: absolute;
    right: 43px;
    top: 0;
    transition: all 0.3s ease-in 0s;
    width: 20px;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}
.noti-contents {
	display: flex;
	flex-direction: column;
    margin-left: 15px;
    color: #a1a1a1;
    justify-content: center;
}
#filter_inputs {
	display: none;
}
.service-img {
	width: 60px;
}
.content-textarea {
	height: 300px;
}
.fav-icon {
	width: 32px;
}
.site-logo {
	max-height: 32px;
    width: auto;
}
.admin-noti-wrapper .noti-list {
    padding: 7px 10px;
    -webkit-transition: 0.2s ease;
    -moz-transition: 0.2s ease;
    transition: 0.2s ease;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    margin-bottom: 5px;
    position: relative;
}
.admin-noti-wrapper .noti-list .noti-avatar {
    left: auto;
    height: 40px;
    width: 40px;
    position: absolute;
}
.admin-noti-wrapper .noti-list .noti-avatar img {
    height: 40px;
    width: 40px;
}
.admin-noti-wrapper .noti-list .noti-contents {
    margin-left: 50px;
	font-size: 13px;
}
.admin-noti-wrapper .noti-list .noti-contents h3 {
	color: #333;
    font-size: 16px;
	margin-bottom: 0;
}

/*-----------------
	16. Responsive
-----------------------*/

@media only screen and (min-width: 992px) {
	.header.fixed-header #toggle_btn {
		align-items: center;
		color: #fff;
		display: inline-flex;
		float: left;
		font-size: 30px;
		height: 60px;
		margin-left: 15px;
		justify-content: center;
		padding: 0 20px;
	}
	.mini-sidebar .header-left .logo img {
		height: auto;
		max-height: 24px;
		width: auto;
	}
	.mini-sidebar .header .header-left .logo {
		display: none;
	}
	.mini-sidebar .header-left .logo.logo-small {
		display: block;
	}
	.mini-sidebar .header .header-left {
		padding: 0 5px;
		width: 90px;
	}
	.mini-sidebar .sidebar {
		width: 90px;
	}
	.mini-sidebar.expand-menu .sidebar {
		width: 280px;
	}
	.mini-sidebar .sidebar-menu {
		padding: 15px 19px;
	}
	.mini-sidebar .menu-title {
		visibility: hidden;
		white-space: nowrap;
	}
	.mini-sidebar.expand-menu .menu-title {
		visibility: visible;
	}
	.mini-sidebar .menu-title a {
		visibility: hidden;
	}
	.mini-sidebar.expand-menu .menu-title a {
		visibility: visible;
	}
	.modal-open.mini-sidebar .sidebar {
		z-index: 1051;
	}
	.mini-sidebar .sidebar .sidebar-menu ul > li > a span {
		display: none;
		transition: all 0.2s ease-in-out;
		opacity: 0;
	}
	.mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
		display: inline;
		opacity: 1;
	}
	.mini-sidebar .page-wrapper {
		margin-left: 90px;
	}
	.mini-sidebar.expand-menu .page-wrapper {
		margin-left: 280px;
	}
	.mini-sidebar .main-wrapper > .header {
		left: 120px;
	}
	.mini-sidebar .header.fixed-header {
		left: 90px;
	}
	.mini-sidebar .sidebar-menu > ul > li > a {
		border-radius: 10px;
		padding: 10px 16px;
	}
	.mini-sidebar.expand-menu .main-wrapper > .header {
		left: 310px;
	}
	.mini-sidebar.expand-menu .main-wrapper > .header.fixed-header {
		left: 280px;
	}
}
@media only screen and (max-width: 991.98px) {
	.header .header-left {
		position: absolute;
		width: 100%;
		display: block;
	}
	.mobile_btn {
		color: #333;
		cursor: pointer;
		display: flex;
		font-size: 26px;
		height: 60px;
		left: 0;
		padding: 0 15px;
		position: absolute;
		text-align: center;
		top: 0;
		z-index: 10;
		justify-content: center;
		align-items: center;
	}
	#toggle_btn {
		display: none;
	}
	.header .header-left .logo {
		display: none;
	}
	.header-left .logo.logo-small {
		display: block;
	}
	.header .header-left .logo img {
		max-height: 36px;
		height: 36px;
	}
	.main-wrapper > .header {
		left: 50px;
	}
	.main-wrapper > .header.fixed-header {
		left: 0;
	}
	.main-wrapper {
		padding: 20px;
	}
	.sidebar {
		margin-left: -225px;
		width: 225px;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
		z-index: 1041;
	}
	.page-wrapper {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		-webkit-transition: all 0.4s ease;
		-moz-transition: all 0.4s ease;
		transition: all 0.4s ease;
	}
	.nav-tabs.menu-tabs .nav-item {
		padding-right: 1.25rem;
	}
}

@media only screen and (max-width: 767.98px) {
	body {
		font-size: 0.9375rem;
	}
	h1, .h1 {
		font-size: 2rem;
	}
	h2, .h2 {
		font-size: 1.75rem;
	}
	h3, .h3 {
		font-size: 1.5rem;
	}
	h4, .h4 {
		font-size: 1.125rem;
	}
	h5, .h5 {
		font-size: 1rem;
	}
	h6, .h6 {
		font-size: 0.875rem;
	}
	.header .has-arrow .dropdown-toggle:after {
		display: none;
	}
	.user-menu.nav > li > a > span:not(.user-img) {
		display: none;
	}
	.navbar-nav .open .dropdown-menu {
		float: left;
		position: absolute;
	}
	.navbar-nav.user-menu .open .dropdown-menu {
		left: auto;
		right: 0;
	}
	.header .header-left {
		padding: 0 15px;
	}
	.header .header-left .logo {
		display: none;
	}
	.header-left .logo.logo-small {
		display: inline-block;
	}
	.login-wrapper .loginbox {
		max-width: 450px;
		min-height: unset;
	}
	.error-box h1 {
		font-size: 6em;
	}
	.error-box .btn {
		font-size: 15px;
		min-width: 150px;
		padding: 8px 20px;
	}
	.dash-count {
		font-size: 16px;
	}
	.nav-tabs.menu-tabs {
		margin-top: -15px;
	}	
	.nav-tabs.menu-tabs .nav-item {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 575.98px) {
	.main-wrapper > .header {
		right: 35px;
		top: 35px;
		left: 35px;
	}
	.card {
		margin-bottom: 0.9375rem;
	}
	.page-wrapper > .content {
		padding: 0.9375rem 0.9375rem 0;
	}
	.card-body {
		padding: 1.25rem;
	}
	.card-header {
		padding: .75rem 1.25rem;
	}
	.card-footer {
		padding: .75rem 1.25rem;
	}
	.page-header {
		margin-bottom: 0.9375rem;
	}
	.loginbox {
		padding: 1.25rem;
	}

}